import MicroModal from 'micromodal';
import FadeIn from '../animations/FadeIn';
import FadeOut from '../animations/FadeOut';

export default function () {
  const fadeInModal = new FadeIn();
  const fadeOutModal = new FadeOut();

  // TODO: https://codepen.io/designcouch/pen/obvKxm
  MicroModal.init({
    onShow: (modal) => {
      fadeInModal.start(modal);

      setTimeout(() => {
        document.documentElement.classList.add('modal-open', `${modal.id}-open`);
      });
    },
    onClose: (modal) => {
      fadeOutModal.start(modal);

      setTimeout(() => {
        document.documentElement.classList.remove('modal-open', `${modal.id}-open`);
      });
    },
  });

  const menuTrigger = document.querySelector('[data-micromodal-trigger="modal-menu"]');

  if (menuTrigger) {
    menuTrigger.addEventListener('click', () => {
      if (document.documentElement.classList.contains('modal-menu-open')) {
        MicroModal.close('modal-menu');
        menuTrigger.classList.remove('is-active');
      } else {
        menuTrigger.classList.add('is-active');
      }
    });
  }
}
