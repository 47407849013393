import MicroModal from 'micromodal';
import Vimeo from '@vimeo/player';
import FadeIn from '../animations/FadeIn';
import FadeOut from '../animations/FadeOut';

export default function () {
  const fadeInModal = new FadeIn();
  const fadeOutModal = new FadeOut();

  document.addEventListener('click', (event) => {
    const { target } = event;

    if (target) {
      if (target.classList.contains('js-video-trigger')) {
        const { videoId } = target.dataset;

        if (videoId) {
          MicroModal.show(`modal-video-${videoId}`, {
            onShow: (modal) => {
              fadeInModal.start(modal);

              const iframe = modal.querySelector('iframe');

              if (iframe && iframe.src.indexOf('vimeo') > -1) {
                const player = new Vimeo(iframe);
                player.play();
              }

              setTimeout(() => {
                document.documentElement.classList.add('modal-open', `${modal.id}-open`);
                [...document.querySelectorAll('.js-video-close-trigger')].forEach((trigger) => {
                  trigger.classList.add('is-active');
                });
              });
            },
            onClose: (modal) => {
              fadeOutModal.start(modal);

              const iframe = modal.querySelector('iframe');

              if (iframe) {
                const videoSrc = iframe.src;

                setTimeout(() => {
                  iframe.src = '';
                  iframe.src = videoSrc;
                }, 500);
              }

              setTimeout(() => {
                document.documentElement.classList.remove('modal-open', `${modal.id}-open`);
                [...document.querySelectorAll('.js-video-close-trigger')].forEach((trigger) => {
                  trigger.classList.remove('is-active');
                });
              });
            },
          });
        }
      }

      if (target.classList.contains('js-video-close-trigger')) {
        const { videoId } = target.dataset;

        if (videoId) {
          MicroModal.close(`modal-video-${videoId}`);
        }
      }
    }
  });
}
