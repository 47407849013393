import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';

gsap.registerPlugin(CSSPlugin);

export default class FadeOut {
  constructor(animationDuration) {
    this.duration = animationDuration || 0.25;
  }

  start(element, cb) {
    const from = {
      display: 'block',
      opacity: 1,
    };

    const to = {
      duration: this.duration,
      ease: 'power1.out',
      display: 'none',
      opacity: 0,
    };

    const animation = gsap.fromTo(element, from, to);

    if (cb) {
      animation.then(cb());
    }
  }
}
