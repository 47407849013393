import $ from 'jquery';
import Parallax from 'parallax-js/dist/parallax';

export default function () {
  [...document.querySelectorAll('.js-parallax-hover')].forEach((scene) => new Parallax(scene, {
    frictionX: 0.05,
    frictionY: 0.05,
  }));

  $(window).scroll(function () { // eslint-disable-line func-names
    $('.js-parallax-fade').css({
      opacity: 1 - (($(this).scrollTop()) / 600),
      transform: `translateY(${($(this).scrollTop()) / 4}px)`,
    });
  });
}
