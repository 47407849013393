import Swiper from 'swiper/js/swiper';

export default function () {
  [...document.querySelectorAll('.swiper-container')].forEach((item) => {
    const slideshow = new Swiper(item, { // eslint-disable-line no-unused-vars
      direction: 'horizontal',
      loop: true,
      preloadImages: true,
      watchOverflow: true,
      // spaceBetween: 20,
      updateOnImagesReady: true,
      freeMode: false,
      freeModeMomentumBounceRatio: 0.1,
      freeModeSticky: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    slideshow.update(true);
  });
}
