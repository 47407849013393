import 'fullpage.js/vendors/scrolloverflow';
import Fullpage from 'fullpage.js/dist/fullpage.extensions.min';

export default function () {
  const fullpage = new Fullpage('#fullpage', { // eslint-disable-line
    licenseKey: 'A9871E14-B4F74D94-9F0A29A8-6ED1C4DA',
    scrollBar: false,
    autoScrolling: true,
    scrollOverflow: true,
  });
}
