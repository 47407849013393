import 'classlist-polyfill';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/blur-up/ls.blur-up';

import documentClasses from './utils/documentClasses';
import fullpage from './components/layout/fullpage';
import modal from './components/layout/modal';
import parallax from './components/layout/parallax';
import swiper from './components/layout/swiper';
import video from './components/layout/video';
import PageTransition from './components/layout/PageTransition';
import FadeIn from './components/animations/FadeIn';

documentClasses();
video();

const pageFunctions = [
  fullpage,
  modal,
  parallax,
  swiper,
];

const pageTransition = new PageTransition(pageFunctions);
pageTransition.start();

const fadeIn = new FadeIn();
fadeIn.start(document.querySelector('body'));

// vh fix
const addVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

addVh();

window.addEventListener('resize', () => {
  addVh();
});
