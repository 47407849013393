import $ from 'jquery';
import MicroModal from 'micromodal';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import FadeOut from '../animations/FadeOut';
import FadeIn from '../animations/FadeIn';

gsap.registerPlugin(ScrollToPlugin);

export default class PageTransition {
  constructor(pageFunctions) {
    this.$main = $('.js-pt-main');
    this.menuTrigger = document.querySelector('[data-micromodal-trigger="modal-menu"]');
    this.changedPage = false;
    this.pageFunctions = pageFunctions;
  }

  start() {
    this.initPage();

    window.setTimeout(() => {
      $(window).on('popstate', () => {
        if (this.changedPage) {
          this.loadPage(window.location.href);
        }
      });
    });

    window.setTimeout(() => {
      $(document).on('click', 'a', (event) => {
        const href = $(event.target).attr('href');

        if (href && (href.indexOf(document.domain) > -1 || href.indexOf(':') === -1)) {
          history.pushState({}, '', href); // eslint-disable-line no-restricted-globals
          this.loadPage(href);
          return false;
        }

        return true;
      });
    });
  }

  initPage() {
    window.setTimeout(() => {
      this.pageFunctions.forEach((func) => func());

      function gtag() {
        window.dataLayer.push(arguments); // eslint-disable-line prefer-rest-params
      }

      if (this.changedPage) {
        window.dataLayer = window.dataLayer || [];

        gtag('js', new Date());
        gtag('config', 'UA-108230674-1');
      }
    });
  }

  ajaxLoad(result) {
    this.initPage();

    const metaUnparsed = $(result).data('meta');
    const bodyClass = $(result).data('class');
    $('body').removeClass().addClass(bodyClass);

    if (metaUnparsed) {
      const meta = JSON.parse(metaUnparsed);

      $('head').find('title').text(meta.title);

      $.each(meta.link, (k, v) => {
        $.each(v, (kk, vv) => {
          $('head').find(`link[${k}="${kk}"]`).attr('href', vv);
        });
      });

      $.each(meta.meta, (k, v) => {
        $.each(v, (kk, vv) => {
          $(`meta[${k}="${kk}"]`).attr('content', vv);
        });
      });
    }

    this.changedPage = true;
  }

  loadPage(href) {
    this.$main.wrapInner('<div class="js-pt-results" />');
    const newResultsHeight = $('.js-pt-results').outerHeight();
    this.$main.height(newResultsHeight);

    const fadeOut = new FadeOut();
    fadeOut.start(
      document.querySelector('.js-pt-wrapper'),
      () => {
        if (document.documentElement.classList.contains('modal-menu-open')) {
          window.setTimeout(() => {
            MicroModal.close('modal-menu');
            this.menuTrigger.classList.remove('is-active');
          }, 250);
        }

        if (typeof fullpage_api !== 'undefined') { // eslint-disable-line
          fullpage_api.destroy('all'); // eslint-disable-line
        }

        gsap.to(window, {
          duration: 0,
          scrollTo: {
            y: 0,
            autoKill: false,
          },
        });
      },
    );

    $.ajax({
      type: 'POST',
      url: href,
      data: `${window.csrfTokenName}=${window.csrfTokenValue}`,
      success: (result) => {
        this.$main.html(result);
        this.$main.wrapInner('<div class="js-pt-results" />');
        const newAjaxResultsHeight = $('.js-pt-results').outerHeight();
        this.$main.height(newAjaxResultsHeight);

        const fadeIn = new FadeIn();
        fadeIn.start(
          document.querySelector('.js-pt-wrapper'),
          () => {
            this.$main.css('height', '');
            this.ajaxLoad(result);
          },
        );
      },
      error: () => {
        console.log('error loading page'); // eslint-disable-line no-console
        window.location.reload();
      },
    });
  }
}
